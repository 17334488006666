<template>
  <div class="detail-page">
    <TextContent
      v-for="textItem in textList"
      :content="textItem.content"
    ></TextContent>
    <div class="content-media">
      <MediaGride
        v-if="mediaList.length"
        :detail="detail"
        :mediaList="mediaList"
        pageType="knowledge"
      ></MediaGride>
    </div>
    <div class="bottom-btn-box" v-if="type != 'dynamicList'">
      <div class="send-btn" @click="onSend">
        <img
          class="send-icon"
          src="@/assets/img/suggestion/share.svg"
          alt="分享"
        />
        <div class="send-text">发送</div>
      </div>
    </div>
  </div>
</template>

<script>
import TextContent from '../../../../components/detail/TextContent'
import MediaGride from '@/components/detail/mediaGride.vue'
import {
  getKnowledgeDetail,
  setBackSend,
  sendMonitor
} from '../../../../service/quickSend.service.js'
import { sendChatMessageAllType } from '../../../../utils/nwechat.js'
import { formatParamsDistribution } from '@/utils/index.js'
import { detail } from '../tabPage/test'
export default {
  components: {
    MediaGride: MediaGride,
    TextContent: TextContent
  },
  data() {
    return {
      detail: {},
      sale_script_id: '',
      textList: [],
      mediaList: [],
      customer_userid: '',
      chat_id: '',
      member_userid: '',
      share_data_id: '', // 埋点id
      type: '' // 判断是否从动态列表过来，如果是，不需要出现发送按钮让其再次发送
    }
  },
  created() {
    this.init()
  },

  methods: {
    async init() {
      // 获取知识话术id
      this.sale_script_id = this.$route.query.id
      this.customer_userid = this.$route.query.customer_userid
      this.chat_id = this.$route.query.chat_id
      this.type = this.$route.query.type
      this.getDetail()

      // 成员id
      // let userInfo = JSON.parse(localStorage.getItem('vuex'))
      // this.member_userid = userInfo.user.info.qw_userid
      // // 客户id
      // try {
      //   let res = await getCurExternalContact()
      //   this.customer_userid = res
      // } catch (e) {
      //   console.log(e)
      // }
    },
    // 获取详情
    async getDetail() {
      // console.log(detail.data.content_arr)
      // this.detail = detail.data || {}
      // this.textList = this.detail.content_arr.filter((item) => {
      //   return item.type == 'TEXT'
      // })
      // this.mediaList = this.detail.content_arr.filter((item) => {
      //   return item.type != 'TEXT'
      // })
      getKnowledgeDetail({
        sale_script_id: this.sale_script_id
      })
        .then((res) => {
          this.detail = res.data || {}
          // 过滤文字内容和媒体内容
          this.textList = this.detail.content_arr.filter((item) => {
            return item.type == 'TEXT'
          })
          this.mediaList = this.detail.content_arr.filter((item) => {
            return item.type != 'TEXT'
          })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    // 发送前的埋点回调
    async beforeSend() {
      let { data, code } = await sendMonitor({
        payable_id: this.sale_script_id,
        type: 'knowledge',
        user_id: this.customer_userid
      })
      if (code == 200) {
        this.share_data_id = data.share_data_id
      }
      return Promise.resolve(true)
    },

    // 点击发送
    async onSend() {
      // 如果发送给个人
      if (this.customer_userid) {
        await this.beforeSend()
      }
      try {
        let mediaList = this.detail.content_arr || []
        // 根据页面类型
        for (let i = 0; i < mediaList.length; i++) {
          // console.log('发送消息', i)
          let type = await this.formatType(mediaList[i].type)
          let params = await this.formatSendParams(
            type,
            mediaList[i],
            this.share_data_id
          )
          let { sendStatus } = await sendChatMessageAllType(type, params, false)
          // console.log('发送状态：-----------：', sendStatus)
          this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
        }
        if (this.taskStatus) {
          this.onSended()
        }
      } catch (e) {
        console.log(e)
      }
    },

    // 发送之后进行回调
    async onSended() {
      let type = 'customer_receive_knowledge'
      let field = 'external_contact'
      if (this.chat_id) {
        type = 'customer_touch_knowledge_group'
        field = 'chat_id'
      }
      await setBackSend({
        sale_script_id: this.sale_script_id,
        event_key: type,
        field: field,
        field_value: this.customer_userid || this.chat_id
      })

      // 发送完成后关闭当前窗口
      // window.location.href = 'about:blank'
      // window.close()
      wx.closeWindow()
    },

    // 判断wechat文件类型
    formatType(medisType) {
      let type = ''
      switch (medisType) {
        case 'TEXT':
          type = 'text'
          break
        case 'IMAGE':
          type = 'image'
          break
        case 'LINK':
          type = 'news'
          break
        case 'VIDEO':
          type = 'video'
          break
        case 'MINIPROGRAM':
          type = 'miniprogram'
          break
        case 'FILE':
          type = 'news'
          break
        default:
          type = 'text'
          break
      }
      // console.log(1, type)
      return Promise.resolve(type)
    },

    async formatSendParams(type, item, share_data_id) {
      console.log('参数', type, item)
      // 缺少http头部，补充
      if (item.file_url && item.file_url.indexOf('http') === -1) {
        item.file_url = 'https://' + item.file_url
      }

      let params = {}
      if (['image', 'video'].includes(type)) {
        params[type] = {
          mediaid: item.media_id
        }
      } else if (type == 'news') {
        // 普通h5
        if (item.type != 'FILE') {
          let imgUrl = item.file_url
            ? item.file_url
            : 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
          params[type] = {
            link: item.qw_link_url, //H5消息页面url 必填
            title: item.qw_link_title, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imgUrl //H5消息封面图片URL
          }
        } else {
          // 文件类型附件，当做h5发出去
          // 环境不同需要更换
          let baseURL = location.origin + '/preview-page?file='
          let imageUrl = this.getFileCover(item)
          params[type] = {
            link: baseURL + item.file_url + `&type=${item.file_suffix}`, //H5消息页面url 必填
            title: item.file_name, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imageUrl //H5消息封面图片URL
          }
        }
      } else if (type == 'miniprogram') {
        let path = item.qw_miniprogram_page
        path = await formatParamsDistribution(
          path,
          this.customer_userid,
          this.chat_id,
          'knowledge'
        )

        // 如果存在埋点id加入埋点id
        if (share_data_id) path = `${path}&share_data_id=${share_data_id}`

        params[type] = {
          appid: item.qw_miniprogram_appid, //小程序的appid
          title: item.qw_miniprogram_title, //小程序消息的title
          imgUrl: item.file_url, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
          page: path
        }
      } else if (type == 'text' && item.content) {
        // params.content = item.content
        params[type] = {
          content: item.content
        }
      } else {
        return ''
      }
      console.log(2, params)
      return Promise.resolve(params)
    },
    // 处理小程序路径加上分配所需参数
    // formatParamsDistribution(path) {
    //   let obj = {
    //     member_id: localStorage.getItem('member_id') || '',
    //     customer_id: this.customer_userid || '',
    //     chart_id: this.chat_id || '',
    //     source: 'knowledge'
    //   }
    //   let tempPath = path
    //   for (let key in obj) {
    //     if (obj[key]) {
    //       tempPath = `${tempPath}&${key}=` + obj[key]
    //     }
    //   }
    //   return Promise.resolve(tempPath)
    // },

    // 发送时获取图片封面
    getFileCover(item) {
      if (['doc', 'docx'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/eb29bd4da195ca9937f6691e8c5cd92b.png'
      } else if (item.type == 'FILE' && ['pdf'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/e4624c7404b38a1a38d28d125716f3c3.png'
      } else if (['xlsx', 'xls'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/063bbd6dff45fee185467d7c12318dfe.png'
      } else if (['pptx', 'ppt'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1f84978621cf8adbc47ebac8e83213dd.png'
      } else {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/style/bottom_btn.less');
.detail-page {
  box-sizing: border-box;
  padding: 15px;
  background-color: #fff;
  min-height: 100vh;
  .content-media {
    padding-top: 8px;
    padding-bottom: 65px;
  }
}
</style>
